import { Divider, MenuItem, Select, Stack, TextField } from "@mui/material";
import { ticketType } from "../constants/types";
import { longText } from "../../App";
import { RoundedButton } from "../form/buttons";
import useScreen from "../hooks/useScreen";
import { fonts, palette } from "../constants/constants";
import { P } from "../typography/headings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import the icon
import { useState } from "react";

export const PageOne: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  //core variables
  const { currentScreen } = useScreen();
  const [value, setValue] = useState("");

  
  return (
    <Stack
      id={"page_one_outer"}
      sx={{ height: "100%", justifyContent: "space-between" }}
    >
      <Stack
        id={"page_one_inner"}
        direction={"column"}
        sx={{
          width: "100%",
          pr: "10px",
          mt: "15px",
          // maxHeight: currentScreen === "desktop" ? "40vh" : "57vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* {longText} */}
        {tickets.map((ticket, index) => {
          return (
            <>
              {/* *outer container */}
              <Stack
                direction={"row"}
                sx={{ justifyContent: "space-between", my: "24px", mx: "0px" }}
              >
                {/* left side of content */}
                <Stack direction={"column"} sx={{ width: "100%" }}>
                  <P
                    sx={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: fonts.medium,
                    }}
                  >
                    {ticket.title}
                  </P>
                  <P
                    sx={{
                      color: palette.primary.indigo,
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: fonts.medium,
                    }}
                  >
                    {"$" + ticket.price}
                  </P>
                  <P
                    sx={{
                      color: "#555759",
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: fonts.regular,
                    }}
                  >
                    {ticket.description}
                  </P>
                </Stack>
                {/* right side dropdown */}
                {/* <Select
                  value={ticket.available}
                  variant="filled"
                  IconComponent={KeyboardArrowDownIcon}
                  style={{
                    height: "30px",
                  }}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {Array.from({ length: ticket.available }, (_, index) => (
                    <MenuItem value={index + 1}>{index + 1}</MenuItem>
                  ))}
                </Select> */}

                <TextField
                 
                  onChange={(e) => setValue(e.target.value)}
                  id={"Select_Item_Style" + index}
                  error={index === 1}
                  select
                
                  variant="standard"
                  sx={{
                    borderBottomColor: "red",
                  }}
                  // SelectProps={{
                  //   style:
                  //     index === 0
                  //       ? {
                  //           background: palette.primary.indigoLight,
                  //           color: palette.primary.indigo,
                  //           overflow: "hidden",
                  //           borderRadius: "5px",
                  //         }
                  //       : undefined,
                  // }}
                  defaultValue={0}
                >
                  {Array.from({ length: ticket.available + 1 }, (_, index) => (
                    <MenuItem  selected  value={index}>{index}</MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  // my: "8px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
            </>
          );
        })}
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: currentScreen === "desktop" ? "100%" : "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          // px: currentScreen === "desktop" ? "" : "16px",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <RoundedButton
          variant="purple"
          onClick={onNext}
          width={currentScreen === "desktop" ? "100%" : "100%"}
        >
          {"Next"}
        </RoundedButton>
      </Stack>
    </Stack>
  );
};

const tickets: ticketType[] = [
  {
    title: "Early Bird",
    description: "This is a little description of what is included",
    price: "15",
    available: 20,
  },
  {
    title: "General Admission",
    description: "This is a little description of what is included",
    price: "32",
    available: 2,
  },
  {
    title: "Early Bird",
    description: "This is a little description of what is included",
    price: "15",
    available: 3,
  },
  {
    title: "General Admission",
    description: "This is a little description of what is included",
    price: "32",
    available: 2,
  },
  {
    title: "Early Bird",
    description: "This is a little description of what is included",
    price: "15",
    available: 3,
  },
  {
    title: "General Admission",
    description: "This is a little description of what is included",
    price: "32",
    available: 2,
  },
  {
    title: "Early Bird",
    description: "This is a little description of what is included",
    price: "15",
    available: 3,
  },
  {
    title: "General Admission",
    description: "This is a little description of what is included",
    price: "32",
    available: 2,
  },
  {
    title: "Early Bird",
    description: "This is a little description of what is included",
    price: "15",
    available: 3,
  },
  {
    title: "General Admission",
    description: "This is a little description of what is included",
    price: "32",
    available: 2,
  },
];
