import { Button } from "@mui/material";
import { fonts, palette } from "../constants/constants";
import { ReactNode } from "react";
import useScreen from "../hooks/useScreen";

export const RoundedButton: React.FC<{
  children: ReactNode;
  onClick?: () => void;
  variant: "purple" | "grey";
  width?: string;
}> = ({ children, onClick, variant, width = "100%" }) => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <Button
    className="customRoundBtn"

      sx={{
        width: width,
        maxWidth: currentScreen == "desktop" ? "322px" : "100%",
        mx: currentScreen == "desktop" ? "auto" : undefined,
        // maxWidth: "100%",
        px: "24px",
        py: "16px",
        borderRadius: "100px",
        bgcolor:
          variant === "purple" ? palette.primary.indigo : palette.greyscale[3],
        color: variant === "purple" ? "white" : palette.primary.indigo,
        fontSize: "16px",
        fontFamily: fonts.bold,
        fontWeight: 700,
        textTransform: "none",
        ":hover": {
          bgcolor: variant === "purple" ?  "#3e33c7" : palette.primary.indigo,
          color: "white",
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const SlimRoundedButton: React.FC<{
  children: ReactNode;
  onClick?: () => void;
  variant: "purple" | "grey";
  width?: string;
}> = ({ children, onClick, variant, width = "100%" }) => {

  const { currentScreen } = useScreen();

  return (
    <Button
      sx={{
        width: currentScreen === "desktop" ? "fit-content" : width,
        mx:"auto",
        px: "24px",
        py: "6px",
        borderRadius: "100px",
        bgcolor:
          variant === "purple" ? palette.primary.indigo : palette.greyscale[3],
        color: variant === "purple" ? "white" : palette.primary.indigo,
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: fonts.bold,
        textTransform: "none",
        ":hover": {
          bgcolor: variant === "purple" ?  "#3e33c7" : palette.primary.indigo,
          color: "white",
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export const SmallRoundedButton: React.FC<{
  children: ReactNode;
  onClick?: () => void;
  variant: "purple" | "grey";
  width?: string;
}> = ({ children, onClick, variant, width = "auto" }) => {
  return (
    <Button
      sx={{
        width: width,
        px: "24px",
        py: "6px",
        borderRadius: "100px",
        bgcolor:
          variant === "purple" ? palette.primary.indigo : palette.greyscale[3],
        color: variant === "purple" ? "white" : palette.primary.indigo,
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: fonts.bold,
        textTransform: "none",
        ":hover": {
          bgcolor: variant === "purple" ?  "#3e33c7" : palette.primary.indigo,
          color: "white",
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
