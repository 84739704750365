import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import { useState } from "react";
import useScreen from "../hooks/useScreen";
import { PageOne } from "./pageOne";
import { PageThree } from "./pageThree";
import { PageTwo } from "./pageTwo";
import { fonts, palette } from "../constants/constants";
import { P } from "../typography/headings";

export const TicketForm: React.FC<{ onLast: () => void }> = ({ onLast }) => {
  //core variables
  const { currentScreen } = useScreen();
  const steps = ["Select tickets", "Your details", "Payment"];

  //state variables
  const [currentStep, setCurrentStep] = useState<0 | 1 | 2>(0);
  const [disableStepper, setDisableStepper] = useState(false);

  return (
    <>
      <Stack
        id={"stepper_container"}
        sx={{
          width: "320px",
          position: "sticky",
          // top: 75,
          bgcolor: "white",
          zIndex: 1,
        }}
      >
        <StepperElement
          currentStep={currentStep}
          steps={steps}
          disableStepper={disableStepper}
        />
      </Stack>
      {/* error box on top */}
      {currentStep === 0 && (
        <Stack
          id={"ticket_shortage_error"}
          sx={{
            width: currentScreen === "desktop" ? "-webkit-fill-available": "calc(100% - 60px)",
            // maxWidth: currentScreen === "desktop" ? "89%" : "100%",
            mx: currentScreen === "desktop" ? "30px" : "0px",
            textAlign: "center",
            // height: "240px",
            // position: "sticky",
            // top: 0,
            p: "10px 10px",
            bgcolor: "rgba(227, 96, 58, 0.1)",
          }}
        >
          <P
            sx={{
              color: palette.primary.poppy,
              fontSize: "16px",
              fontWeight: 500, fontFamily: fonts.medium,
              // maxWidth: currentScreen === "desktop" ? "auto" : "250px",
              maxWidth:"100%",
              // mx: "20px",
            }}
          >
            {"The ticket tier that you selected “Early bird” has sold out"}
          </P>
        </Stack>
      )}
      <Stack
        id={"modal_lower_container"}
        direction={"column"}
        sx={{
          // mt: "16px",
          width: "100%",
          height: "100%",
          maxHeight: "calc(100% - 16px)",
          overflowY: "auto",
        }}
      >
        <Stack id={"steps_container"} direction={"column"} sx={{ height: "100%", px: currentScreen === "desktop" ? "30px" : "20px" }}>
          {currentStep === 0 && <PageOne onNext={() => setCurrentStep(1)} />}
          {currentStep === 1 && <PageTwo onNext={() => setCurrentStep(2)} />}
          {currentStep === 2 && (
            <PageThree onLastPage={() => { setDisableStepper(true); onLast() }} />
          )}
        </Stack>
      </Stack>
    </>
  );
};

const StepperElement: React.FC<{
  currentStep: 0 | 1 | 2;
  steps: string[];
  disableStepper: boolean;
}> = ({ currentStep, steps, disableStepper }) => {
  //core variables
  const { currentScreen } = useScreen();

  return (
    <Stepper
      activeStep={currentStep}
      alternativeLabel
      // connector={<ColorlibConnector />}
      sx={{
        display: (disableStepper && currentScreen === "mobile") ? "none" : "",
        width: currentScreen === "desktop" ? "320px" : "100%",
        "& .MuiStepConnector-line": {
          minHeight: "40px",
        },
        marginX: "auto",
        marginBottom: "16px",
        mt: "16px",
        minHeight: "50px",
      }}
    >
      {
        steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                sx={{
                  color: "white",
                  fontFamily: fonts.bold
                }}
              // StepIconComponent={CustomStepIcon}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
    </Stepper>
  );
};



