import {
  Button,
  Divider,
  Input,
  InputBase,
  Link,
  Stack,
  SxProps,
  TextField,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { P } from "../typography/headings";
import { useState } from "react";
import { fonts, palette } from "../constants/constants";
import React from "react";
import useScreen from "../hooks/useScreen";
import {
  RoundedButton,
  SlimRoundedButton,
  SmallRoundedButton,
} from "../form/buttons";

// icon
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

export const PageThree: React.FC<{ onLastPage: () => void }> = ({
  onLastPage,
}) => {
  //core variables
  const { currentScreen } = useScreen();
  //state variables
  const [pageState, setPageState] = useState<
    "card" | "confirmation" | "code" | "success" | "failure"
  >("card");
  return (
    <Stack
      id={"page_three_outer"}
      direction={"column"}
      sx={{
        gap: "16px",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {pageState === "card" && (
        <CardDetailState onNextState={() => setPageState("confirmation")} />
      )}
      {pageState === "confirmation" && (
        <ConfirmationState
          onDiscount={() => setPageState("code")}
          onNextState={() => {
            setPageState("success");
            onLastPage();
          }}
        />
      )}
      {pageState === "code" && (
        <CodeState
          onNextState={() => {
            setPageState("failure");
            onLastPage();
          }}
        />
      )}
      {pageState === "success" && <SuccessState />}
      {pageState === "failure" && <FailureState />}
    </Stack>
  );
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(239, 238, 255, 1)",
    borderRadius: "10px",
    padding: "16px",
  },
}));

const CardDetailState: React.FC<{ onNextState: () => void }> = ({
  onNextState,
}) => {
  //core variables
  const { currentScreen } = useScreen();
  const ButtonSx: SxProps<Theme> = {
    textTransform: "none",
    borderRadius: "25px",
    p: "10px 15px",
  };
  //state variables
  const [cardType, setCardType] = useState<"visa" | "other">("visa");
  const [isTooltipOpen, setisTooltipOpen] = useState(false);
  return (
    <>
      <Stack
        id={"card_details_section"}
        sx={{
          p: currentScreen === "desktop" ? "" : "0px 10px 0px 0px",
          gap: "16px",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          overflow: "auto",
        }}
      >
        <Stack
          direction={"column"}
          sx={{
            height: "100%",
            gap: "16px",
          }}
        >
          <P
            sx={{
              color: "#000",
              fontSize: "18px",
              fontWeight: 700,
              fontFamily: fonts.bold,
            }}
          >
            {"Your card details"}
          </P>
          <Stack
            direction={"row"}
            sx={{
              gap: "6px",
              alignItems: "start",
              mb: "16px",
              mx: currentScreen === "desktop" ? "0px" : "auto",
            }}
          >
            {/* VISA AND OTHER CARD SELECTION */}
            <Stack
              direction={"row"}
              sx={{
                border: "2px solid " + palette.greyscale[3],
                borderRadius: "25px",
                padding: "2px",
                width: "max-content",
              }}
            >
              <Button
                sx={{
                  ...ButtonSx,
                  backgroundColor:
                    cardType === "visa" ? "#EFEEFF" : "transparent",
                  color:
                    cardType === "visa" ? palette.primary.indigo : "#555759",
                  ":hover": {
                    backgroundColor:
                      cardType === "visa" ? "#EFEEFF" : "transparent",
                  },
                  ":active": {
                    backgroundColor:
                      cardType === "visa" ? "#EFEEFF" : "transparent",
                  },
                  ":focus": {
                    backgroundColor:
                      cardType === "visa" ? "#EFEEFF" : "transparent",
                  },
                }}
                onClick={() => setCardType("visa")}
              >
                {"Visa debit"}
              </Button>
              <Button
                sx={{
                  ...ButtonSx,
                  backgroundColor:
                    cardType === "other" ? "#EFEEFF" : "transparent",
                  color:
                    cardType === "other" ? palette.primary.indigo : "#555759",
                  ":hover": {
                    backgroundColor:
                      cardType === "other" ? "#EFEEFF" : "transparent",
                  },
                  ":active": {
                    backgroundColor:
                      cardType === "other" ? "#EFEEFF" : "transparent",
                  },
                  ":focus": {
                    backgroundColor:
                      cardType === "other" ? "#EFEEFF" : "transparent",
                  },
                }}
                onClick={() => setCardType("other")}
              >
                {"Other"}
              </Button>
            </Stack>
            {/* INFORMATION TOOLTIP */}
            <CustomTooltip
              open={isTooltipOpen}
              placement="top"
              sx={
                currentScreen === "desktop"
                  ? { inset: "auto auto 0px 0px" }
                  : { inset: "auto auto 0px -20px !important" }
              }
              id="CustomTooltip"
              title={
                <React.Fragment>
                  <P
                    sx={{
                      color: "#000",
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: fonts.regular,
                    }}
                  >
                    {"Lorem Ipsum Title"}
                  </P>

                  <P
                    sx={{
                      color: palette.greyscale[50],
                      fontSize: "12px",
                      fontWeight: 400,
                      fontFamily: fonts.regular,
                    }}
                  >
                    {"Lorem Ipsum content of the tooltip to be placed here."}
                  </P>
                  <CloseIcon
                    sx={{
                      position: "absolute",
                      fill: "#000",
                      top: "5px",
                      right: "5px",
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                    fontSize={"small"}
                    onClick={() => setisTooltipOpen(false)}
                  />
                </React.Fragment>
              }
            >
              <img
                onClick={() => setisTooltipOpen((prevState) => !prevState)}
                src={"/icons/info.svg"}
                alt="tooltip"
              />
            </CustomTooltip>
          </Stack>


              <Stack gap={0}>
              <P
                sx={{
                  color: "#000",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: fonts.bold,
                  
                }}
              >
                {"Cardholder name"}
              </P>
              <TextField variant="standard" placeholder="Cardholder name" />
              <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px",
                  mb: "30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />

              <Stack direction={"row"} sx={{ gap: "5%" }}>
                <Stack sx={{ width: "60%" }}>
                  <P
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: fonts.bold,
                    }}
                  >
                    {"Card Number"}
                  </P>
                  <TextField
                    variant="standard"
                    placeholder={"Card Number"}
                    sx={{ width: "100%" }}
                  />
                </Stack>

                <Stack sx={{ width: "20%" }}>
                  <P
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: fonts.bold,
                    }}
                  >
                    {"Expiry"}
                  </P>
                  <TextField
                    variant="standard"
                    placeholder="MM/YY"
                    error
                    helperText="Error demo"
                    sx={{ width: "100%" }}
                  />
                </Stack>

                <Stack sx={{ width: "20%" }}>
                  <P
                    sx={{
                      color: "#000",
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: fonts.bold,
                    }}
                  >
                    {"CVC"}
                  </P>
                  <TextField
                    variant="standard"
                    placeholder={"CVC"}
                    sx={{ width: "100%" }}
                  />
                </Stack>
              </Stack>

              <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px",
                  mb: "30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />

              <Stack>
                <P
                  sx={{
                    color: "#000",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: fonts.bold,
                  }}
                >
                  {"Postal Code"}
                </P>
                <TextField
                  className="inputTypeText"
                  variant="standard"
                  placeholder={"Postal Code"}
                />
              </Stack>
              <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px",
                  mb: "30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />

              </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: currentScreen === "desktop" ? "100%" : "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          mt: "auto",
          mx: currentScreen === "desktop" ? "" : "auto",
          // px: currentScreen === "desktop" ? "" : "16px",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <RoundedButton
          variant="purple"
          // width={currentScreen === "desktop" ? "100%" : "92%"}
          onClick={onNextState}
        >
          {"Next"}
        </RoundedButton>
      </Stack>
    </>
  );
};

const ConfirmationState: React.FC<{
  onDiscount: () => void;
  onNextState: () => void;
}> = ({ onDiscount, onNextState }) => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <>
      <Stack
        id={"confirmation_state_section"}
        sx={{
          p: currentScreen === "desktop" ? "" : "0px 10px 0px 0px",
          gap: "5px",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          overflow: "auto",
        }}
      >
        <P
          sx={{
            color: "#000",
            fontSize: "18px",
            fontWeight: 700,
            fontFamily: fonts.bold,
            mb: "15px",
          }}
        >
          {"Confirm Order"}
        </P>
        <ConfirmationStateItem
          label={"Early bird"}
          quantity={1}
          price={"$32.00"}
        />
        <ConfirmationStateItem
          label={"General Admission"}
          quantity={1}
          price={"$32.00"}
        />
        <ConfirmationStateItem
          label={"Name char 30 char limit lorem ipsum"}
          quantity={1}
          price={"$32.00"}
        />
        <ConfirmationStateItem label={"Fee"} quantity={""} price={"$2.73"} />
        <Stack
          direction={"row"}
          sx={{
            // border: "2px solid " + palette.greyscale[6],
            backgroundColor: "#EFEEFF",
            padding: "10px",
            pr: "15px",
            borderRadius: "5px",
            mt: "3px",
          }}
        >
          <P
            sx={{
              color: palette.primary.indigo,
              width: "50%",
              fontWeight: 700,
              fontFamily: fonts.bold,
              fontSize: "16px",
            }}
          >
            {"Total"}
          </P>
          <P
            sx={{
              color: "#000",
              width: "50%",
              textAlign: "right",
              fontWeight: 700,
              fontFamily: fonts.bold,
            }}
          >
            {"$96.00"}
          </P>
        </Stack>
        <Link
          sx={{
            color: palette.primary.indigo,
            textAlign: "right",
            fontSize: "14px",
            fontWeight: 500,
            fontFamily: fonts.medium,
            textDecoration: "none",
            cursor: "pointer",
            ":hover": {
              textDecoration: "underline",
            },
          }}
          onClick={onDiscount}
        >
          {"Discount code?"}
        </Link>
      </Stack>

      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: currentScreen === "desktop" ? "100%" : "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          mt: "auto",
          mx: currentScreen === "desktop" ? "" : "auto",
          // px: currentScreen === "desktop" ? "" : "16px",
          alignItems: "center",
          bgcolor: "white",
          gap: "10px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{ alignItems: "center", mx: "auto", gap: "5px" }}
        >
          <img src="/icons/card.svg" alt="card" />
          <Stack direction={"column"} sx={{ alignItems: "start" }}>
            <P
              sx={{
                color: "#000",
                fontSize: "14px",
                fontWeight: 700,
                fontFamily: fonts.bold,
              }}
            >
              {"Visa debit"}
            </P>
            {/* <P sx={{ color: "#00000080", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium, }}>
              {"⏺⏺⏺⏺ ⏺⏺⏺⏺ ⏺⏺⏺⏺ 3748"}
            </P> */}
            {/* <FiberManualRecordIcon  sx={{color:"gray",width:'13px',height:"13px"}}/> */}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: "-5px",
              }}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <React.Fragment key={index}>
                  <FiberManualRecordIcon
                    sx={{ color: "gray", width: "13px", height: "13px" }}
                  />
                  {(index + 1) % 4 === 0 && index !== 11 && (
                    <span style={{ marginRight: "10px" }} />
                  )}
                </React.Fragment>
              ))}

              <P
                sx={{
                  color: "#00000080",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: fonts.medium,
                  ml: "10px",
                }}
              >
                {"3748"}
              </P>
            </Stack>
          </Stack>
        </Stack>
        <RoundedButton
          variant="purple"
          // width={currentScreen === "desktop" ? "100%" : "92%"}
          onClick={onNextState}
        >
          {"Pay"}
        </RoundedButton>
      </Stack>
    </>
  );
};

const CodeState: React.FC<{ onNextState: () => void }> = ({ onNextState }) => {
  //core variables
  const { currentScreen } = useScreen();

  //state variables
  const [text, setText] = useState("");

  return (
    <>
      <Stack
        id={"code_state_section"}
        sx={{
          p: currentScreen === "desktop" ? "" : "0px 10px 0px 0px",
          gap: "16px",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          overflow: "auto",
        }}
      >
        {/* <TextField
          variant="filled"
          label={"ENTER CODE"}
          fullWidth
          sx={{
            my: "auto",
            textAlign: "center",
          }}
        /> */}
        <InputBase
          type={"text"}
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="Enter code"
          sx={{
            backgroundColor: palette.greyscale[3],
            textAlign: "center",
            fontSize: "14px",
            py: "10px",
            borderRadius: "5px",
            mb: "auto",
            mt: "20%",
            "& input": {
              textAlign: "center",
              fontSize: "16px",
              color: text ? palette.primary.indigo : palette.greyscale[100],
              "&::placeholder": {
                textAlign: "center",
                color: text ? palette.primary.indigo : "rgba(0,0,0,0.5)", // Adjusted placeholder text color
                opacity: 0.75,
                fontFamily: fonts.regular
              },
            },
          }}
        />
      </Stack>

      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: currentScreen === "desktop" ? "100%" : "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          mt: "auto",
          mx: currentScreen === "desktop" ? "" : "auto",
          // px: currentScreen === "desktop" ? "" : "16px",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <RoundedButton
          variant="purple"
          // width={currentScreen === "desktop" ? "100%" : "92%"}
          onClick={onNextState}
        >
          {"Apply Discount Code"}
        </RoundedButton>
      </Stack>
    </>
  );
};

const SuccessState: React.FC = () => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <>
      <Stack
        id={"success_state_section"}
        sx={{
          p: currentScreen === "desktop" ? "" : "0px 10px 0px 0px",
          gap: "16px",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          overflow: "auto",
        }}
      >
        <Stack
          direction={"column"}
          id={"inner_content_div_success"}
          sx={{
            alignItems: "center",
            height: "100%",
            mt: currentScreen === "desktop" ? "32px" : "40px",
          }}
        >
          <img src="/icons/success.svg" alt="success" />
          <Typography
            sx={{
              textAlign: "center",
              maxWidth:  "100%",
              mt: "32px",
              mb: "20px",
            }}
          >
            <span
              style={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: fonts.medium,
              }}
            >
              {"You bought 3 tickets to "}
            </span>
            <span
              style={{
                color: palette.primary.indigo,
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: fonts.medium,
              }}
            >
              {"Birthday Kegger"}
            </span>
            <span
              style={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: fonts.medium,
              }}
            >
              {" by Marcus Smith"}
            </span>
          </Typography>
          <Stack
            direction={"column"}
            sx={{
              // padding: "10px",
              border: "2px solid " + palette.greyscale[8],
              borderRadius: "5px",
              width: "99%",
              mb: "10px",
              mx: "10px",
              pt: "8px",
              pb: "8px",
              gap: "10px",
            }}
          >
            <SuccessStateItem label={"Name"} value={"Colin Lee"} />
            <SuccessStateItem
              label={"Order ID"}
              value={"317621821::27dhu8-jds8s-dhs8"}
            />
            <SuccessStateItem label={"Total charged"} value={"$33.50"} />
            <SuccessStateItem label={"Phone number"} value={"+14165602999"} />
            <SuccessStateItem label={"Email"} value={"colin@mosea.io"} />
          </Stack>
          <P
            sx={{ color: "#000", fontSize: "14px", fontFamily: fonts.regular }}
          >
            {
              "Your order confirmation has been sent and your QR code will be sent to you the day before the event."
            }
          </P>
        </Stack>
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: currentScreen === "desktop" ? "100%" : "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          mt: "auto",
          mx: currentScreen === "desktop" ? "" : "auto",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <RoundedButton
          variant="grey"
          // width={currentScreen === "desktop" ? "100%" : "92%"}
          // onClick={onNextState}
        >
          {"Share"}
        </RoundedButton>
      </Stack>
    </>
  );
};

const FailureState: React.FC = () => {
  //core variables
  const { currentScreen } = useScreen();

  const onNextState =()=>{
    
  }
  return (
    <>
      <Stack
        id={"failure_state_section"}
        sx={{
          p: currentScreen === "desktop" ? "" : "0px 10px 0px 0px",
          gap: "16px",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
          overflow: "auto",
        }}
      >
        <Stack
          id={"inner_div_failure"}
          direction={"column"}
          sx={{
            alignItems: "center",
            height: "100%",
            mt: currentScreen === "desktop" ? "32px" : "40px",
          }}
        >
          <img style={{ marginBottom: currentScreen === "desktop" ? "32px" : "32px",}} src="/icons/failure.svg" alt="success" />
          <Typography
            sx={{
              textAlign: "center",
              maxWidth: "100%",
              // mt: "16px",
              mb: "16px",
            }}
          >
            <span
              style={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: fonts.medium,
              }}
            >
              {"Your ticket purchase for "}
            </span>
            <span
              style={{
                color: palette.primary.indigo,
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: fonts.medium,
              }}
            >
              {"Birthday Kegger"}
            </span>
            <span
              style={{
                color: "#000",
                fontSize: "18px",
                fontWeight: 500,
                fontFamily: fonts.medium,
              }}
            >
              {" did not go through"}
            </span>
          </Typography>

          <SlimRoundedButton variant="grey" width="fit-content">
            {"Contact us"}
          </SlimRoundedButton>
        </Stack>
      </Stack>

      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: currentScreen === "desktop" ? "100%" : "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          mt: "auto",
          // px: currentScreen === "desktop" ? "" : "16px",
          mx: currentScreen === "desktop" ? "" : "auto",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <RoundedButton
          variant="purple"
          // width={currentScreen === "desktop" ? "100%" : "92%"}
          onClick={onNextState}
        >
          {"Please try again"}
        </RoundedButton>
      </Stack>
    </>
  );
};

export const ConfirmationStateItem: React.FC<{
  label: string;
  quantity: number | "";
  price: string;
}> = ({ label, quantity, price }) => {
  return (
    <Stack
      direction={"row"}
      sx={{
        border: "2px solid " + palette.greyscale[6],
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <P
        sx={{
          color: palette.greyscale[50],
          width: "60%",
          fontWeight: 400,
          fontFamily: fonts.regular,
        }}
      >
        {label}
      </P>
      <P
        sx={{
          color: palette.primary.indigo,
          width: "20%",
          textAlign: "center",
          fontWeight: 500,
          fontFamily: fonts.medium,
        }}
      >
        {quantity}
      </P>
      <P
        sx={{
          color: "#000",
          width: "20%",
          textAlign: "right",
          fontWeight: 400,
          fontFamily: fonts.regular,
          // paddingRight: "10px",
        }}
      >
        {price}
      </P>
    </Stack>
  );
};

export const SuccessStateItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => {
  const { currentScreen } = useScreen();

  return (
    <Stack
      direction={"row"}
      sx={{ alignItems: "center", p: "0px 8px", flex: 1 }}
    >
      <P
        sx={{
          // width: "50%",
          textAlign: "left",
          color: "#000",
          fontSize: "16px",
          fontWeight: 500,
          fontFamily: fonts.medium,
          width: "115px",
        }}
      >
        {label}
      </P>
      <P
        sx={{
          textAlign: "left",
          color: palette.greyscale[50],
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: fonts.regular,
          flex: 1,
          ml: currentScreen === "desktop" ? "30px" : "15px",
        }}
      >
        {value}
      </P>
    </Stack>
  );
};
