import {
  Divider,
  Input,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { H2, P } from "../typography/headings";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { fonts, palette } from "../constants/constants";
import { RoundedButton, SlimRoundedButton } from "../form/buttons";
import useScreen from "../hooks/useScreen";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ReactFlagsSelect from "react-flags-select";
import { countryCodes } from "../constants/countryCodes";
import { TransformedCountryMap } from "../constants/types";
import { SmallRoundedButton } from "../form/buttons";

export const PageTwo: React.FC<{ onNext: () => void }> = ({ onNext }) => {
  //core variables
  const { currentScreen } = useScreen();
  const ChevronSx = { color: palette.primary.indigo };
  //state variables
  const [showSummary, setShowSummary] = useState(false);
  const [formSection, setFormSection] = useState<
    "phone" | "otp" | "information" | "confirmation" | "notme"
  >("phone");

  return (
    <>
      {/* OUTER CONTAINER */}
      <Stack
        id={"page_two_outer"}
        direction={"column"}
        sx={{
          width: currentScreen === "desktop" ? "100%" : "auto",
          gap: "16px",
          p: "0px 10px 0px 0px",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {/* TICKET SUMMARY HEADING */}
        <Stack direction={"row"} justifyContent={"space-between"}>
          <P
            sx={{
              color: "black",
              fontSize: "18px",
              fontWeight: 700,
              fontFamily: fonts.bold,
            }}
          >
            {"Ticket Summary"}
          </P>
          <Stack
            direction={"row"}
            sx={{
              cursor: "pointer",
              alignItems: "center",
            }}
            onClick={() => setShowSummary((prev) => !prev)}
          >
            <P
              sx={{
                color: palette.primary.indigo,
                fontSize: "12px",
                fontWeight: 400,
                fontFamily: fonts.regular,
                ":hover": {
                  textDecoration: "underline",
                }
              }}
            >
              {"Details"}
            </P>
            <ExpandLessIcon
              sx={{
                color: palette.primary.indigo,
                transform: showSummary ? "" : "rotate(-180deg)",
                transition: "all 0.2s",
              }}
            />
          </Stack>
        </Stack>

        {/* DETAILS PANEL */}
        <Stack
          direction={"column"}
          gap={"5px"}
          sx={{
            height: showSummary ? "auto" : "0px",
            // maxHeight: "180px",
            overflow: showSummary ? "" : "hidden",
            transition: "all 0.2s",
            mb: "-7px",
            mt: "-7px",
          }}
        >
          {ticketSummary.map((ticket, index) => (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              sx={{
                p: "10px",
                borderRadius: "5px",
                border: "2px solid var(--grey-6, #F1F2F2)",
                background: "#FFF",
              }}
            >
              <P
                sx={{
                  color: palette.greyscale[50],
                  width: "60%",
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: fonts.regular,
                }}
              >
                {ticket.title}
              </P>
              <P
                sx={{
                  color: palette.primary.indigo,
                  fontSize: "16px",
                  fontWeight: 500, fontFamily: fonts.medium,
                }}
              >
                {ticket.quantity}
              </P>
              <P
                sx={{
                  color: palette.greyscale[100],
                  fontSize: "14px",
                  fontWeight: 400,
                  fontFamily: fonts.regular,
                }}
              >
                {ticket.price}
              </P>
            </Stack>
          ))}
        </Stack>

        {/* TOTAL COST PANEL */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            px: "13px",
            py: "9px",
            bgcolor: "#EFEEFF",
            borderRadius: "5px"
          }}
        >
          <P
            sx={{
              color: palette.primary.indigo,
              fontSize: "16px",
              fontWeight: 700, fontFamily: fonts.bold,
            }}
          >
            {"3 tickets"}
          </P>
          <P
            sx={{
              color: "#000",
              fontSize: "14px",
              fontWeight: 700,
              fontFamily: fonts.bold,
            }}
          >
            {"$96.00"}
          </P>
        </Stack>

        {/* INFORMATION FORM SECTION */}
        {formSection === "phone" && <PhoneSection />}
        {formSection === "otp" && <OTPSection />}
        {formSection === "information" && (
          <InformationSection onNotMe={() => setFormSection("notme")} />
        )}
        {formSection === "confirmation" && <ConfirmationSection />}
        {formSection === "notme" && <NotMeSection />}
      </Stack>
      {/* NEXT BUTTON */}
      <Stack
        direction={"column"}
        sx={{
          position: "sticky",
          width: "100%",
          bottom: 0,
          pt: "16px",
          pb: "20px",
          // px: currentScreen === "desktop" ? "0px" : "30px",
          mx: currentScreen === "desktop" ? "" : "auto",
          alignItems: "center",
          bgcolor: "white",
        }}
      >
        <RoundedButton
          variant="purple"
          onClick={() => {
            formSection === "phone"
              ? setFormSection("otp")
              : formSection === "otp"
                ? setFormSection("information")
                : formSection === "information"
                  ? setFormSection("confirmation")
                  : formSection === "confirmation"
                    ? onNext()
                    : onNext();
          }}
        // width={currentScreen === "desktop" ? "100%" : "92%"}
        >
          {"Next"}
        </RoundedButton>
      </Stack>
    </>
  );
};

const InformationItem: React.FC<{ label: string; content: string }> = ({
  label,
  content,
}) => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <Stack
      direction={"row"}
      sx={{
        alignItems:"top",
        // justifyContent: "space-between",
        flex:1
      }}
    >
      <P
        sx={{
          color: "#000", fontSize: "16px", fontWeight: 600,
          fontFamily: fonts.medium,
          width: "116px"
        }}
      >
        {label}
      </P>
      <P
        sx={{
          color: palette.greyscale[50],
          fontSize: "14px",
          fontWeight: 400,
          fontFamily: fonts.regular,
          textAlign: "left",
          flex:1,
          // width: currentScreen === "desktop" ? "65%" : "80%",
          // width:"100% - 150px",
          ml:currentScreen === "desktop" ? "30px":"15px"
        }}
      >
        {content}
      </P>
    </Stack>
  );
};

export const ConfirmationItem: React.FC<{ label: string; content: string }> = ({
  label,
  content,
}) => {
  return (
    <>
      <TextField variant="standard" placeholder={label} value={content} />
    </>
  );
};

const PhoneSection: React.FC = () => {
  //core variables
  const { currentScreen } = useScreen();
  //state variables
  const [selected, setSelected] = useState("CA");
  return (
    <Stack
      id={"phone_section"}
      direction={"column"}
      sx={{
        gap: "16px",
        height: currentScreen === "desktop" ? "100%" : "100%",
        pr: "10px"
      }}
    >
      <P sx={{
        color: "#000", fontSize: "18px", fontWeight: 700,
        fontFamily: fonts.bold, mt: "21px"
      }}>
        {"Enter your phone number"}
      </P>

      <Stack direction={"column"} sx={{ gap: "10px" }}>
        <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium,  }}
        >
          {"Phone number"}
        </P>
        <Stack direction={"row"} sx={{ gap: "5px" }} id={"flagContainer"}>
          {/* <TextField variant="standard" select sx={{ maxHeight: "100px" }}>
            {countryCodes.map((country) => (
              <MenuItem key={country.name} value={country.dial_code}>
                {country.flag + " " + country.code}
              </MenuItem>
            ))}
          </TextField> */}
          <ReactFlagsSelect
            searchable
            // searchPlaceholder="enter"
            selected={selected}
            onSelect={(code) => setSelected(code)}
            customLabels={countryCodes.reduce(
              (acc: TransformedCountryMap, country) => {
                console.log(country,"co")
                acc[country.code] = {
                  primary: country.code,
                  secondary: "(" + country.dial_code + ")",
                };
                return acc;
              },
              {}
            )}
            placeholder={"Select"}
          />
          <TextField variant="standard" placeholder="+123 000 000" fullWidth  sx={{verticalAlign: "middle",pt:"12px"}}    />
        </Stack>
      </Stack>
      <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px",
                  mb: "30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
    </Stack>
  );
};

//state variables
const OTPSection: React.FC = () => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <Stack
      direction={"column"}
      sx={{
        height: currentScreen === "desktop" ? "100%" : "100%",
        mt: "15px"
      }}
    >
      <P sx={{
        color: "#000", fontSize: "18px", fontWeight: 700,
        fontFamily: fonts.bold, mb: "18px"
      }}>
        {"Enter the code we texted you"}
      </P>

      <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium, mb: "-16px" }}
        >
          {"Code"}
        </P>
      <TextField  sx={{mt:0}} variant="standard"  label={"‏"} fullWidth   InputLabelProps={{
        shrink: true, // Set to false to prevent label sliding
      }}/>
     
      <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  // my: "30px",
                  mt: "5px",
                  mb: "30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
      {/* <Stack direction={"column"}>
      <P sx={{ color: "#000", fontSize: "16px", fontWeight: 500 , fontFamily: fonts.medium, }}>{"Code"}</P>
      <P
        sx={{
          color: palette.primary.indigo,
          fontSize: "14px",
          fontWeight: 400,
                fontFamily: fonts.regular,
        }}
      >
        {"34829"}
      </P>
    </Stack> */}
    </Stack>
  );
};

const InformationSection: React.FC<{ onNotMe: () => void }> = ({ onNotMe }) => {
  const { currentScreen } = useScreen();
  return (
    <Stack
      direction={"column"}
      sx={{
        gap: "16px",
        height: currentScreen === "desktop" ? "100%" : "100%",
      }}
    >
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          mt: "25px"
        }}
      >
        <P sx={{
          color: "#000", fontSize: "18px", fontWeight: 700,
          fontFamily: fonts.bold,
        }}>
          {"Your Information"}
        </P>
        <SmallRoundedButton
          variant="grey"
          onClick={onNotMe}
        // sx={{
        //   color: palette.primary.indigo,
        //   fontSize: "12px",
        //   fontWeight: 700,,
        // fontFamily: fonts.bold,
        //   padding: "10px 16px",
        //   backgroundColor: palette.greyscale[3],
        //   borderRadius: "100px",
        //   cursor: "pointer",
        //   transition: "all 0.2s",
        //   ":hover": {
        //     color: "white",
        //     bgcolor: palette.primary.indigo,
        //   },
        //   ":active": {},
        // }}
        >
          {"This is not me"}
        </SmallRoundedButton>
      </Stack>

      <Stack
        direction={"column"}
        sx={{
          gap: "10px",
          p: "10px",
          border: "2px solid " + palette.greyscale[6],
          borderRadius: "5px"
        }}
      >
        <InformationItem label={"First name"} content={"Colin"} />
        
        <InformationItem
          label={"Last name"}
          content={"Name 50 char limit lorem ipsum dolor sit ameenstur"}
        />
        <InformationItem label={"Phone number"} content={"+14165602999"} />
      </Stack>
      {/* <Stack direction={"column"}>
      <P sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium, }}>{"Email"}</P>
      <P
        sx={{
          color: palette.primary.indigo,
          fontSize: "14px",
          fontWeight: 400,
                fontFamily: fonts.regular,
        }}
      >
        {"colin@mosea.io"}
      </P>
    </Stack> */}
    <Stack gap={0}>
    <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium,  }}
        >
          {"Email"}
        </P>
        
      <TextField variant="standard"  placeholder="abc@xyz" />
      <Divider
      sx={{ borderBottomWidth: "2px", borderColor: palette.greyscale[6], mt: "5px", mb:"30px"}}
    />
    </Stack>
    </Stack>
  );
};

const ConfirmationSection: React.FC = () => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <Stack
      direction={"column"}
      sx={{
        // gap: "26px",
        height: currentScreen === "desktop" ? "100%" : "100vh",
      }}
    >
      <P sx={{
        color: "#000", fontSize: "18px", fontWeight: 700,
        fontFamily: fonts.bold,
        my: "25px"
      }}>
        {"Your information"}
      </P>

      {/* <Stack sx={{ height: "100%", overflowY: "auto" }}> */}
      <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium,  }}
        >
          {"First Name"}
        </P>
      <ConfirmationItem label={" ‏"}   content={"Colin"} />
      <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px", 
                  mb:"30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
                <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium, }}
        >
          {"Last Name"}
        </P>
      <ConfirmationItem label={" ‏"} content={"Lee"} />
      <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px", 
                  mb:"30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
    <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium, }}
        >
          {"Email"}
        </P>
      <ConfirmationItem label={" colin@mosea.io"} content={"colin@mosea.io"} />
      <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  mt: "5px", 
                  mb:"30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
                <P
          sx={{ color: "#000", fontSize: "16px", fontWeight: 500, fontFamily: fonts.medium,  }}
        >
          {"Phone Number"}
        </P>
      <ConfirmationItem label={" ‏"} content={"+14165602999"} />
      <Divider
                variant="middle"
                sx={{
                  width: "100%",
                  // maxWidth: currentScreen === "desktop" ? "96%" : "94%",
                  // my: "30px",
                  mt: "5px", 
                  mb:"30px",
                  mx: "auto",
                  borderBottomWidth: "2px",
                  borderColor: palette.greyscale[6],
                }}
              />
      {/* </Stack> */}
    </Stack>
  );
};

const NotMeSection: React.FC = () => {
  //core variables
  const { currentScreen } = useScreen();
  return (
    <Stack
      direction={"column"}
      sx={{
        gap: "16px",
        height: currentScreen === "desktop" ? "100%" : "100vh",
      }}
    >
      <P sx={{ color: "#000", fontSize: "18px", fontWeight: 700, mt: "20px" }}>
        {"This is not me and..."}
      </P>
      <SlimRoundedButton variant="grey">
        {"This is not my phone number"}
      </SlimRoundedButton>
      <SlimRoundedButton variant="grey">
        {"This is my phone number"}
      </SlimRoundedButton>
      <SlimRoundedButton variant="grey">{"Go back"}</SlimRoundedButton>
    </Stack>
  );
};

const ticketSummary = [
  {
    title: "Early bird",
    quantity: 1,
    price: "$32.00",
  },
  {
    title: "General Admission",
    quantity: 1,
    price: "$32.00",
  },
  {
    title: "Name 30 char limit lorem ipsum",
    quantity: 1,
    price: "$32.00",
  },
];
