import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { fonts, palette } from "./components/constants/constants";
import {
  stepConnectorClasses,
  stepLabelClasses,
  textFieldClasses,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  typography: {
    fontFamily: fonts.regular, // Use "DM Sans" as the default font
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: palette.primary.indigo,
      // dark: will be calculated from palette.primary.main,
      // contrastText: CONSTANTS.CONTRAST_TEXT,
    },
  },
  components: {
    MuiStepConnector: {
      styleOverrides: {
        root: {
          marginLeft: "-8px",
          marginRight: "-8px",
          borderTopWidth: "2px",
        },
        line: {
          borderColor: "#7F7F7F", // Default line color
          borderTopWidth: "2px",
        },
        active: {
          borderColor: "yellow",
          "& $line": {
            borderColor: "blue", // Completed line color
          },
        },
        completed: {
          borderColor: "yellow",
          "& $line": {
            borderColor: "blue", // Completed line color
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: palette.primary.indigo, // Change this to your desired input text color
          // "&::placeholder": {
          //   color: "red",
          // },
          fontSize: "14px"
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: "#000",
            fontSize: "16px",
            fontWeight: 700,
            fontFamily: fonts.bold
          },
          color: "#000",
        },
        // error: {
        //   "&.Mui-focused": {
        //     color: "#d1e", // Change this to your desired label color
        //   },
        // },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          // margin: 0,
          // backgroundColor: "#7F7F7F",
          borderRadius: "100%",
          color: "#7F7F7F",
          // padding: "0px",
          // Adjust the size or margin of the step icon if necessary
        },
        active: {
          backgroundColor: palette.primary.indigo,
          borderRadius: "100%",
          color: "#fff",
        },
        completed: {
          backgroundColor: palette.primary.indigo,
          borderRadius: "100%",
          fill: "#fff",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          // Default label color
          color: "#7F7F7F",
          fontFamily: fonts.medium
        },
        active: {
          [`&.${stepLabelClasses.active} .${stepLabelClasses.label}`]: {
            // Label color when the step is active
            color: "red",
          },
        },
        completed: {
          [`&.${stepLabelClasses.label} .${stepLabelClasses.completed} `]: {
            // Label color when the step is completed
            color: "red",
          },
          color: "red",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: KeyboardArrowDownIcon, // Use KeyboardArrowDownIcon as the dropdown icon
      },
      styleOverrides: {
        standard: {
          color: palette.greyscale[50],
        },
        select: {
          ":active": {
            color: palette.primary.indigo,
          },
          ":focus": {
            color: palette.primary.indigo,
          },
        },
      },
    },
    MuiMenuList: {
      styleOverrides: {
        root: {
          maxHeight: "250px", // Set the max height to 250px
        },
      },
    },
    // hiding all kinda bottom line for textInput
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline': {
            '&:before': {
              borderBottom: 'none', // Hides the underline before user input
            },
            '&:after': {
              borderBottom: 'none', // Hides the underline after user input
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none', // Hides the underline on hover
            },
            '&:active:before': {
              borderBottom: 'none', // Hides the underline on active state
            },
            '&:focus:before': {
              borderBottom: 'none', // Hides the underline on focus state
            },
          },
        },
      },
    }
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
