import {
  Backdrop,
  Dialog,
  DialogContent,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { fonts, palette } from "../constants/constants";
import useScreen from "../hooks/useScreen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { P } from "../typography/headings";
import { TicketForm } from "./TicketForm";
import { useState } from "react";

export const BookingModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  //core variables
  const { currentScreen } = useScreen();

  //state variables
  const [showBackIcon, setShowBackIcon] = useState(true);

  //jsx
  const backIconsx: SxProps<Theme> = {
    display: (!showBackIcon) ? "none" : "",
    zIndex: 1,
    mr: "11px",
    position: "absolute",
    top: "15px",
    // right: currentScreen === "desktop" ? 0 : "",
    left: currentScreen === "desktop"? "20px" : "12px",
    fill: palette.greyscale[25],
    cursor: "pointer",
    ":hover": {
      fill: "#000",
    },
  };
  const closeIconsx: SxProps<Theme> = {
    // display: (!showBackIcon) ? "none" : "",
    zIndex: 1,
    mr: "11px",
    position: "fixed",
    top: "15px",
    // right: currentScreen === "desktop" ? 0 : "",
    right: "10px",
    fill: "#000",
    cursor: "pointer",
    ":hover": {
      fill: "#000",
    },
  };

  return (
    <Dialog
      id={"dialog_box"}
      open={isOpen}
      fullScreen={currentScreen === "mobile"}
      maxWidth={"xl"}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "#6256FFAA",
          },
        },
      }}
      onClose={onClose}
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <DialogContent
        id={"dialog_content"}
        sx={{
          maxHeight: currentScreen === "desktop" ? "700px" : "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          overflowY: "hidden",
          height: currentScreen === "desktop" ? "100vh" : "100vh",
          // Height: "700px",
        }}
      >
        {(currentScreen === "desktop") && (
          <CloseIcon sx={closeIconsx} onClick={onClose} />
        )}
      
      <Stack className="backArrow">

          <ArrowBackIcon sx={backIconsx} onClick={onClose} />
      </Stack>
        
        <Stack
          id={"modal_content_container"}
          direction={"column"}
          sx={{
            alignItems: "center",
            // width: currentScreen === "desktop" ? "50vw" : "100%",
            width:"100%",
            minWidth: currentScreen === "desktop" ? "544px" : "100%",
            maxWidth: currentScreen === "desktop" ? "544px" : "1",
            // marginX: currentScreen === "desktop" ? "30px" : "",
            marginTop: "15px",
            backgroundColor: "white",
            height: currentScreen === "desktop" ? "100%" : "100%",
            maxHeight: currentScreen === "desktop" ? "700px" : "100%",
            overflowX: "hidden",
          }}
        >
          <P
            sx={{
              color: palette.primary.indigo,
              width: "100%",
              textAlign: "center",
              position: "sticky",
              // top: 20,
              backgroundColor: "white",
              // mx: currentScreen === "desktop" ? undefined : "50px",
              maxWidth: currentScreen === "desktop" ? "100%" : "80%",
              fontSize: "16px",
              fontWeight: 500, fontFamily: fonts.medium,
              lineHeight:1.3
            }}
          >
            {"Title 50 char limit lorem ipsum dolor sit amenstur"}
          </P>
          <P
            sx={{
              color: "#00000080",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: fonts.regular,
              width: "100%",
              textAlign: "center",
              position: "sticky",
              // top: 42,
              backgroundColor: "white",
            }}
          >
            {"Nov 4, 2023 8:00pm - Nov 5, 2023 2:00am"}
          </P>
          <TicketForm onLast={() => setShowBackIcon(false)} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};



